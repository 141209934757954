import { GeneralContentConfig2Routes } from '@/config/contentConfig';
import {
  createRouter,
  createWebHistory,
  createWebHashHistory,
} from 'vue-router';

/***
 * 注意: admin下的路由参数,name值为完整路由,
 * 在config.ts处理进行跳转
 * 例子:
 *  {
 *    path: 'index',
      name: '/admin/inform/index',     // 完整路径
      title: '协会简介',
      component: () => import('@/views/admin/dynamic/Inform.vue'),
 * }
 */

/**
 * admin端公共父容器组件
 */

const Layout = () => import('@/views/admin/admin-components/Layout.vue');
const redirectPath = /Android|webOS|iPhone|iPad|BlackBerry|iPod/i.test(
  navigator.userAgent,
)
  ? '/m_Index'
  : '/p_Index';

const UserAgentMap = {
  WEB: 'web',
  MOBILE: 'mobile',
};
const userAgent = /Android|webOS|iPhone|iPad|BlackBerry|iPod/i.test(
  navigator.userAgent,
)
  ? UserAgentMap.MOBILE
  : UserAgentMap.WEB;
if (userAgent === UserAgentMap.MOBILE) {
  if (window.location.href.indexOf('p_') >= 0) {
    window.location.href = window.location.href.replace('p_', 'm_');
  }
} else if (userAgent === UserAgentMap.WEB) {
  if (window.location.href.indexOf('m_') >= 0) {
    window.location.href = window.location.href.replace('m_', 'p_');
  }
}

/** pc端前台路由 */
const PCWebRouters = [
  {
    path: '/p_Index',
    name: 'p_Index',
    component: () => import('@/views/pc/Layout.vue'),
    meta: {
      type: 'pc',
    },
    children: [
      {
        path: '/p_Index',
        name: 'p_Index',
        component: () => import('@/views/pc/Index.vue'),
        meta: {
          type: 'pc',
        },
      },
      ...GeneralContentConfig2Routes(),
      {
        path: '/p_contactUs',
        name: 'p_contactUs',
        component: () =>
          import('@/components/pc/general-content/content/ContactUs.vue'),
        meta: {
          type: 'pc',
        },
      },
      {
        path: '/p_search',
        name: 'p_search',
        component: () =>
          import('@/components/pc/general-content/content/SearchResult.vue'),
        meta: {
          type: 'pc',
        },
      },
      {
        path: '/Detail',
        name: 'Detail',
        component: () => import('@/views/pc/Detail.vue'),
        meta: {
          type: 'pc',
        },
      },
    ],
  },
];
/** pc后台路由 */
const adminRouters = [
  {
    path: '/admin',
    // name: 'admin',
    title: '首页',
    component: () => import('@/views/admin/Layout.vue'),
    meta: {
      type: 'pc',
    },
    children: [
      {
        path: '/admin',
        name: 'admin',
        title: '首页',
        limits: 'A0000',
        icon: '',
        component: () => import('@/views/admin/Index.vue'),
        children: [],
      },
      {
        path: 'introductory',
        name: 'Introductory',
        title: '协会概况',
        limits: 'B0000',
        icon: 'orgicon-shouye-1xiehuitongzhitubiao',
        component: Layout,
        children: [
          {
            path: 'index',
            name: '/admin/introductory/index',
            title: '协会简介',
            limits: 'B0001',
            component: () =>
              import('@/views/admin/introductory/Introductory.vue'),
          },
          {
            path: 'constitution',
            name: '/admin/introductory/constitution',
            title: '协会章程',
            limits: 'B0002',
            component: () =>
              import('@/views/admin/introductory/Constitution.vue'),
          },
          {
            path: 'institution',
            name: '/admin/introductory/institution',
            title: '协会制度',
            limits: 'B0003',
            component: () =>
              import('@/views/admin/introductory/Institution.vue'),
          },
          {
            path: 'partyBranch',
            name: '/admin/introductory/partyBranch',
            title: '功能型党支部',
            limits: 'B0004',
            component: () =>
              import('@/views/admin/introductory/PartyBranch.vue'),
          },
          {
            path: 'membershipRoster',
            name: '/admin/introductory/membershipRoster',
            title: '会员名册',
            limits: 'B0005',
            component: () =>
              import('@/views/admin/introductory/MembershipRoster.vue'),
          },
          {
            path: 'council',
            name: '/admin/introductory/council',
            title: '理事会',
            limits: 'B0006',
            component: () => import('@/views/admin/introductory/Council.vue'),
          },
          {
            path: 'supervise',
            name: '/admin/introductory/supervise',
            title: '监事会',
            limits: 'B0007',
            component: () => import('@/views/admin/introductory/Supervise.vue'),
          },
          {
            path: 'secretariat',
            name: '/admin/introductory/secretariat',
            title: '秘书处',
            limits: 'B1003',
            component: () =>
              import('@/views/admin/introductory/Secretariat.vue'),
          },
          {
            path: 'specialCommittee',
            name: '/admin/introductory/specialCommittee',
            title: '专门委员会',
            limits: 'B0008',
            component: () =>
              import('@/views/admin/introductory/SpecialCommittee.vue'),
            children: [],
          },
          {
            path: 'professionalCommittee',
            name: '/admin/introductory/professionalCommittee',
            title: '专业委员会',
            limits: 'B0009',
            component: () =>
              import('@/views/admin/introductory/ProfessionalCommittee.vue'),
          },
          {
            path: 'info',
            name: '/admin/introductory/info',
            title: '会员权益专门委员会',
            limits: 'A0010',
            hideInMenu: true,
            component: () =>
              import('@/views/admin/introductory/CommitteeDetials.vue'),
          },
          {
            path: 'editEditor',
            name: '/admin/introductory/editEditor',
            title: '长沙市破产管理人协会理事会议规则',
            limits: 'A0011',
            hideInMenu: true,
            component: () =>
              import('@/views/admin/admin-components/EditEditor.vue'),
          },
        ],
      },
      {
        path: 'inform',
        name: 'informName',
        title: '协会动态',
        limits: 'C0000',
        icon: 'orgicon-shouye-2touzirenzhaomugonggao',
        component: Layout,
        children: [
          {
            path: 'index',
            name: '/admin/inform/index',
            title: '协会通知',
            limits: 'C0001',
            component: () =>
              import('@/views/admin/dynamic/AssociationNotice.vue'),
          },
          {
            path: 'importantNews',
            name: '/admin/inform/importantNews',
            title: '要闻资讯',
            limits: 'C0002',
            component: () => import('@/views/admin/dynamic/ImportantNews.vue'),
          },
        ],
      },
      {
        path: 'announcement',
        name: 'announcement',
        title: '公告通知',
        limits: 'D0000',
        icon: 'orgicon-shouye-6pochanxiehui',
        component: Layout,
        children: [
          {
            path: 'index',
            name: '/admin/announcement/index',
            title: '招募投资人公告',
            limits: 'D0001',
            component: () =>
              import('@/views/admin/announcement/RecruitingInvestors.vue'),
          },
          {
            path: 'creditor',
            name: '/admin/announcement/creditor',
            title: '债权人会议公告',
            limits: 'D0002',
            component: () => import('@/views/admin/announcement/Creditor.vue'),
          },
          {
            path: 'manager',
            name: '/admin/announcement/manager',
            title: '管理人公告',
            limits: 'D0003',
            component: () => import('@/views/admin/announcement/Manager.vue'),
          },
          {
            path: 'bankruptcyCase',
            name: '/admin/announcement/bankruptcyCase',
            title: '案破产件信息公告',
            limits: 'D0004',
            component: () =>
              import('@/views/admin/announcement/BankruptcyCase.vue'),
          },
          {
            path: 'assetAuction',
            name: '/admin/announcement/assetAuction',
            title: '重大资产拍卖公告',
            limits: 'D0005',
            component: () =>
              import('@/views/admin/announcement/AssetAuction.vue'),
          },
          {
            path: 'preReforming',
            name: '/admin/announcement/preReforming',
            title: '预重整公告',
            limits: 'D0006',
            component: () =>
              import('@/views/admin/announcement/PreReforming.vue'),
          },
        ],
      },
      {
        path: 'theoretical',
        name: 'theoretical',
        title: '理论探讨',
        limits: 'E0000',
        icon: 'orgicon-shouye-4liluntantao',
        component: Layout,
        children: [
          {
            path: 'index',
            name: '/admin/theoretical/index',
            title: '理论研究',
            limits: 'E0001',
            component: () => import('@/views/admin/theoretical/Research.vue'),
          },
          {
            path: 'classicCase',
            name: '/admin/theoretical/classicCase',
            title: '典型案例',
            limits: 'E0002',
            component: () =>
              import('@/views/admin/theoretical/ClassicCase.vue'),
          },
        ],
      },
      {
        path: 'training',
        name: 'training',
        title: '培训学习',
        limits: 'F0000',
        icon: 'orgicon-shouye-5peixunxuexi',
        component: Layout,
        children: [
          {
            path: 'index',
            name: '/admin/training/index',
            title: '培训通知',
            limits: 'F0001',
            component: () => import('@/views/admin/training/Notice.vue'),
          },
          {
            path: 'trends',
            name: '/admin/training/trends',
            title: '培训动态',
            limits: 'F0002',
            component: () => import('@/views/admin/training/Trends.vue'),
          },
          {
            path: 'course',
            name: '/admin/training/course',
            title: '培训课程',
            limits: 'F0003',
            component: () => import('@/views/admin/training/Course.vue'),
          },
          {
            path: 'trendsEditor',
            name: '/admin/training/trendsEditor',
            title: '新增新闻',
            limits: 'F0004',
            hideInMenu: true,
            component: () => import('@/views/admin/training/TrendsEditor.vue'),
          },
        ],
      },
      {
        path: 'law',
        name: 'law',
        title: '法律法规',
        limits: 'G0000',
        icon: 'orgicon-shouye-3falvfagui',
        component: Layout,
        children: [
          {
            path: 'index',
            name: '/admin/law/index',
            title: '法律法规、司法解释及司法文件',
            limits: 'G0001',
            component: () => import('@/views/admin/law/Rule.vue'),
          },
          {
            path: 'regulations',
            name: '/admin/law/regulations',
            title: '部门规章、全国规范性文件',
            limits: 'G0002',
            component: () => import('@/views/admin/law/Regulations.vue'),
          },
          {
            path: 'court',
            name: '/admin/law/court',
            title: '地方法院指导文件',
            limits: 'G0003',
            component: () => import('@/views/admin/law/Court.vue'),
          },
          {
            path: 'government',
            name: '/admin/law/government',
            title: '地方政府指导文件',
            limits: 'G0004',
            component: () => import('@/views/admin/law/Government.vue'),
          },
        ],
      },
      {
        path: 'fileDownload',
        name: 'fileDownload',
        title: '文件下载',
        limits: 'H0000',
        icon: 'orgicon-a-huaban10',
        component: Layout,
        children: [
          {
            path: 'index',
            name: '/admin/fileDownload/index',
            title: '协会制度',
            limits: 'H0001',
            component: () =>
              import('@/views/admin/file-download/AssociationSystem.vue'),
          },
          {
            path: 'apply',
            name: '/admin/fileDownload/apply',
            title: '入会申请',
            limits: 'H0002',
            component: () => import('@/views/admin/file-download/Apply.vue'),
          },
        ],
      },
      {
        path: '/contact',
        name: '/admin/contact',
        title: '联系我们',
        limits: 'I0000',
        icon: 'orgicon-a-huaban11',
        component: () => import('@/views/admin/Contact.vue'),
        children: [],
      },
      {
        path: '/banner',
        name: '/admin/banner',
        title: 'banner/ad管理',
        limits: 'J0000',
        icon: 'orgicon-a-huaban12',
        component: () => import('@/views/admin/banner/Index.vue'),
        children: [],
      },
      {
        path: '/linkManagement',
        name: '/admin/linkManagement',
        title: '链接管理',
        limits: 'K0000',
        icon: 'orgicon-a-huaban13',
        component: () => import('@/views/admin/link-management/Index.vue'),
        children: [],
      },
      {
        path: '/role',
        name: '/admin/role',
        title: '角色管理',
        limits: 'L0000',
        icon: 'orgicon-a-huaban14',
        component: () => import('@/views/admin/role-management/Index.vue'),
        children: [],
      },
      {
        path: '/personnel',
        name: '/admin/personnel',
        title: '人员管理',
        limits: 'M0000',
        icon: 'orgicon-a-huaban15',
        component: () => import('@/views/admin/personnel-management/Index.vue'),
        children: [],
      },
    ],
  },
];
/** 移动端前台路由 */
const mobileRouters = [
  {
    path: '/m_Index',
    component: () => import('@/views/mobile/Layout.vue'),
    meta: {
      type: 'mobile',
      level: 1,
    },
    children: [
      {
        path: '',
        name: 'm_Index',
        component: () => import('@/views/mobile/Index.vue'),
        meta: {
          type: 'mobile',
          level: 2,
        },
        children: [],
      },
      {
        path: '/m_SearchResult/:data',
        name: 'm_SearchResult',
        component: () => import('@/views/mobile/components/SearchResult.vue'),
        meta: {
          type: 'mobile',
          level: 2,
        },
      },
      {
        path: '/m_Detail',
        name: 'm_Detail',
        component: () => import('@/views/mobile/Detail.vue'),
        meta: {
          type: 'mobile',
          level: 2,
        },
      },
      {
        path: '/m_contactUs',
        name: 'm_contactUs',
        component: () => import('@/views/mobile/ContactUs.vue'),
        meta: {
          type: 'mobile',
          level: 2,
        },
      },
      {
        path: '/m_Generalization',
        name: 'm_Generalization',
        redirectPath: '/m_Generalization',
        component: () => import('@/views/mobile/Generalization.vue'),
        meta: {
          type: 'mobile',
          level: 2,
        },
      },
      {
        path: '/m_Generalization/:data',
        name: 'm_GeneralizationWithData',
        component: () => import('@/views/mobile/Generalization.vue'),
        meta: {
          type: 'mobile',
          level: 2,
        },
      },
      /** 协会概况 专门委员会 */
      {
        path: '/m_Generalization/specialCommittee',
        name: 'm_GeneralizationSpecialCommittee',
        component: () => import('@/views/mobile/Generalization.vue'),
        meta: {
          type: 'mobile',
          level: 2,
        },
      },
      {
        path: '/m_Generalization/specialCommittee/:id',
        name: 'm_GeneralizationSpecialCommitteeWithId',
        component: () => import('@/views/mobile/CommitteeDetials.vue'),
        meta: {
          type: 'mobile',
          level: 2,
        },
      },
      /** 协会概况 专业委员会 */
      {
        path: '/m_Generalization/professionalCommittee',
        name: 'm_GeneralizationProfessionalCommittee',
        component: () => import('@/views/mobile/Generalization.vue'),
        meta: {
          type: 'mobile',
          level: 2,
        },
      },
      {
        path: '/m_Generalization/professionalCommittee/:id',
        name: 'm_GeneralizationProfessionalCommitteeWithId',
        component: () => import('@/views/mobile/CommitteeDetials.vue'),
        meta: {
          type: 'mobile',
          level: 2,
        },
      },
      {
        path: '/m_Generalization/:data',
        name: 'm_GeneralizationWithData',
        component: () => import('@/views/mobile/Generalization.vue'),
        meta: {
          type: 'mobile',
          level: 2,
        },
      },
      {
        path: '/m_Generalization/:data/Detail/:id',
        name: 'm_GeneralizationWithDetailId',
        component: () => import('@/views/mobile/Detail.vue'),
        meta: {
          type: 'mobile',
          level: 2,
        },
      },
      /** 会员名册 */
      {
        path: '/m_MemberRegistration',
        name: 'm_MemberRegistration',
        component: () => import('@/views/mobile/MemberRegistration.vue'),
        meta: {
          type: 'mobile',
        },
      },
      /** 专门委员会 */
      {
        path: '/m_Committee',
        name: 'm_Committee',
        component: () => import('@/views/mobile/Committee.vue'),
        meta: {
          type: 'mobile',
        },
      },
      /** 专门委员会详情 */
      {
        path: '/m_CommitteeDetials',
        name: 'm_CommitteeDetials', //
        component: () => import('@/views/mobile/CommitteeDetials.vue'),
        meta: {
          type: 'mobile',
        },
      },
      /** 协会动态 */
      {
        path: '/m_Dynamic',
        name: 'm_Dynamic',
        component: () => import('@/views/mobile/Dynamic.vue'),
        meta: {
          type: 'mobile',
        },
      },
      {
        path: '/m_Dynamic/:data',
        name: 'm_DynamicWithData',
        component: () => import('@/views/mobile/Dynamic.vue'),
        meta: {
          type: 'mobile',
        },
      },
      {
        path: '/m_Dynamic/:data/Detail/:id',
        name: 'm_DynamicWithDataWithDetailId',
        component: () => import('@/views/mobile/Detail.vue'),
        meta: {
          type: 'mobile',
        },
      },
      /** 公告通知 */
      {
        path: '/m_Notification',
        name: 'm_Notification',
        component: () => import('@/views/mobile/Notification.vue'),
        meta: {
          type: 'mobile',
        },
      },
      {
        path: '/m_Notification/:data',
        name: 'm_NotificationWithData',
        component: () => import('@/views/mobile/Notification.vue'),
        meta: {
          type: 'mobile',
        },
      },
      {
        path: '/m_Notification/:data/Detail/:id',
        name: 'm_NotificationWithDataWithDetailId',
        component: () => import('@/views/mobile/Detail.vue'),
        meta: {
          type: 'mobile',
        },
      },
      /** 理论探讨 */
      {
        path: '/m_Theory',
        name: 'm_Theory',
        component: () => import('@/views/mobile/Theory.vue'),
        meta: {
          type: 'mobile',
        },
      },
      {
        path: '/m_Theory/:data',
        name: 'm_TheoryWithData',
        component: () => import('@/views/mobile/Theory.vue'),
        meta: {
          type: 'mobile',
        },
      },
      {
        path: '/m_Theory/:data/Detail/:id',
        name: 'm_TheoryWithDataWithDetailId',
        component: () => import('@/views/mobile/Detail.vue'),
        meta: {
          type: 'mobile',
        },
      },
      /** 培训学习 */
      {
        path: '/m_Train',
        name: 'm_Train',
        component: () => import('@/views/mobile/Train.vue'),
        meta: {
          type: 'mobile',
        },
      },
      {
        path: '/m_Train/:data',
        name: 'm_TrainWithData',
        component: () => import('@/views/mobile/Train.vue'),
        meta: {
          type: 'mobile',
        },
      },
      {
        path: '/m_Train/:data/Detail/:id',
        name: 'm_TrainWithDataWithDetail',
        component: () => import('@/views/mobile/Detail.vue'),
        meta: {
          type: 'mobile',
        },
      },
      /** 法律法规 */
      {
        path: '/m_lawsRegulations',
        name: 'm_lawsRegulations',
        component: () => import('@/views/mobile/LawsRegulations.vue'),
        meta: {
          type: 'mobile',
        },
      },
      {
        path: '/m_lawsRegulations/:data',
        name: 'm_lawsRegulationsWithData',
        component: () => import('@/views/mobile/LawsRegulations.vue'),
        meta: {
          type: 'mobile',
        },
      },
      {
        path: '/m_lawsRegulations/:data/Detail/:id',
        name: 'm_lawsRegulationsWithDataWithDetailId',
        component: () => import('@/views/mobile/Detail.vue'),
        meta: {
          type: 'mobile',
        },
      },
      /** 文件下载 */
      {
        path: '/m_download',
        name: 'm_download',
        component: () => import('@/views/mobile/Download.vue'),
        meta: {
          type: 'mobile',
        },
      },
      {
        path: '/m_download/:data',
        name: 'm_downloadWithData',
        component: () => import('@/views/mobile/Download.vue'),
        meta: {
          type: 'mobile',
        },
      },
    ],
  },
];
/** 全部路由 */
const routes = [
  {
    path: '/',
    redirect: redirectPath,
  },
  ...adminRouters,
  ...(userAgent === UserAgentMap.WEB ? PCWebRouters : mobileRouters),
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    meta: {
      type: 'pc',
    },
    component: () => import('@/views/404.vue'),
  },
  {
    path: '/login',
    name: '/login',
    meta: {
      type: 'pc',
    },
    component: () => import('@/views/admin/Login.vue'),
  },
  // {
  //   path: '/Detail',
  //   name: 'Detail',
  //   component: () => import('@/views/pc/Detail.vue'),
  //   meta: {
  //     type: 'pc'
  //   }
  // }
];
const router = createRouter({
  // history: createWebHashHistory(),
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, form, next) => {
  // 当移动端访问pc端页面时,
  if (
    /Android|webOS|iPhone|iPad|BlackBerry|iPod/i.test(navigator.userAgent) &&
    to.meta.type !== 'mobile'
  ) {
    const path = router.options.routes.filter(
      (ele) => ele?.name?.split('_')[1] === to?.name?.split('_')[1],
    )[0].path;
    if (path !== '/') {
      next('m_' + path.split('_')[1]);
    } else {
      next('/');
    }
  }
  // 当pc端访问移动端页面时
  if (
    !/Android|webOS|iPhone|iPad|BlackBerry|iPod/i.test(navigator.userAgent) &&
    to.meta.type !== 'pc'
  ) {
    const currentRoute = router.options.routes.filter(
      (ele) => ele?.name?.split('_')[1] === to?.name?.split('_')[1],
    )[0];
    if (currentRoute) {
      next('p_' + currentRoute.path.split('_')[1]);
    } else {
      next('/');
    }
  }
  if (to.name === '/admin/introductory/info') {
    window.localStorage.setItem('isDetail', '1');
  } else {
    window.localStorage.removeItem('isDetail');
  }
  next();
});

router.afterEach(() => {
  // chrome
  document.body.scrollTop = 0;
  // firefox
  document.documentElement.scrollTop = 0;
  // safari
  window.pageYOffset = 0;
});

export default router;
