/**
 * pc端前台 除首页外其他页面，均使用此文件配置
 * 此文件中的 GeneralContentConfig
 * 用在了nav，左侧menu及导航上。
 */

// export const pageType = {
//   DetailList: {
//     name: 'detailList',
//     component: () => import('../components/detail-components/DetailList.vue'),
//   },
//   Detail: {
//     name: 'detail',
//     component: () => import('../components/detail-components/Detail.vue'),
//   },
// };
// export function GetPageType() {
//   let result: any = {};
//   Object.values(pageType).forEach((item) => {
//     result[item.name] = item.component;
//   });
//   return result;

import { PytNavListType } from '@/types/component';
import { ElStep } from 'element-plus';
import { defineAsyncComponent } from 'vue-demi';

import api from '@/api/interface';

// }
export type ContentTypeEnumType =
  | 'text'
  | 'fileList'
  | 'textList'
  | 'contact'
  | 'tables'
  | 'cards'
  | 'mediaList';

export const ContentTypeMap: {
  text: 'text';
  fileList: 'fileList';
  textList: 'textList';
  contact: 'contact';
  tables: 'tables';
  cards: 'cards';
  mediaList: 'mediaList';
} = {
  text: 'text',
  fileList: 'fileList',
  textList: 'textList',
  contact: 'contact',
  tables: 'tables',
  cards: 'cards',
  mediaList: 'mediaList',
};

/**
 * 监事会、理事会、功能型党支部用查询数据的方法
 * @param params
 * @returns
 */
function getCouncilOrSupervisor(params) {
  return api.getCouncilOrSupervisor(params).then((res) => {
    console.log('card的res数据', res);
    if (res.code === '0') {
      let groups = {};
      res.data.forEach((item) => {
        groups[item.position]
          ? groups[item.position].push(item)
          : (groups[item.position] = [item]);
      });
      /**
       * 将上面对象形式分好组的数据
       * {
       * groupName1: [group1Items],
       * groupName1: [group2Items]
       * ...
       * }
       * 转换成二维数组
       * [
       * {groupName:groupName1, items: [group1Items]},
       * {groupName:groupName2, items: [group2Items]}
       * ...
       * ]
       */
      return Object.entries(groups).map((item) => ({
        groupName: item[0],
        items: item[1],
      }));
    } else {
      return [];
    }
  });
}

/**  获取文章列表使用的方法*/
function getArticleList(params) {
  return api.getArticleList(params).then((res) => {
    if (res.code === '0') {
      return res.data;
    } else {
      return null;
    }
  });
}
/** 获取文件列表使用的方法*/
function getAllFileList(params) {
  return api.getAllFileList(params).then((res) => {
    if (res.code === '0') {
      return res.data;
    } else {
      return null;
    }
  });
}

/** 获取委员会menu列表的方法*/
async function getProfessional(params) {
  return await api.getProfessionalOrSpecial(params).then((res) => {
    return res.data.records.map((item) => ({
      id: item.committeeId,
      name: item.name,
    }));
  });
}

/**
 * 通用内容配置 二级菜单配置类型
 */
// export type GeneralContentMenuType = {
//   name: string;
//   displayText?: string;
//   type?: ContentTypeEnumType;
//   path?: string;
//   dataGetter?: (params: any) => Promise<any> | any;
//   children?:
//     | { [propname: string]: GeneralContentMenuType }
//     | (GeneralContentMenuType & { path: string; dynamic: true });
// };
export type GeneralContentMenuType = {
  name: string;
  displayText?: string;
  type?: ContentTypeEnumType;
  path?: string;
  /** 防止重复的键 */
  key?: string;
  /** el-menu 用index */
  index: string;
  routePath?: string;
  dataGetter?: (params?: any) => Promise<any> | any;
  children?: {
    [propname: string]:
      | GeneralContentMenuType
      | (GeneralContentMenuType & {
          path: string;
          dynamic: true;
          menuGetter: () => Promise<
            Array<{
              id: string; // 拼接到menu的路由上 用于跳转
              name: string; // menu上展示的名称
            }>
          >;
        });
  };
};

/** 通用内容配置 一级导航配置 */
export type GeneralContentType =
  | {
      name: string;
      menus: { [propname: string]: GeneralContentMenuType };
      component?: null;
      routePath?: string;
    }
  | {
      name: string;
      displayText?: string;
      component: any; // () => Promise<typeof import('*.vue')>;
      menus?: null;
      routePath?: string;
    };

/**
 * 通用内容用的配置
 * 一级配置为 导航对应的一级路由
 * 二级配置及二级配置中的children均为 二级路由
 */
export const GeneralContentConfig: { [propName: string]: GeneralContentType } =
  {
    /**协会概况 */
    p_Generalization: {
      name: '协会概况',
      menus: {
        Instroduction: {
          name: '协会简介',
          displayText: '·  协会简介',
          type: ContentTypeMap.text,
          dataGetter: () => {},
        },
        // 协会章程
        Chater: {
          name: '协会章程',
          displayText: '·  协会章程',
          type: ContentTypeMap.text,
          dataGetter: () => {},
        },
        // 协会制度
        Institution: {
          name: '协会制度',
          displayText: '·  协会制度',
          type: ContentTypeMap.textList,
          dataGetter: (params) => {
            return getArticleList({ ...params, dirCode: 'B0003' });
          },
        },
        PartyBranch: {
          name: '功能型党支部',
          displayText: '·  功能型党支部',
          type: ContentTypeMap.contact,
          dataGetter: () => {
            return getCouncilOrSupervisor({
              dirCode: 'B0004',
            });
          },
        },
        MembershipRoster: {
          name: '会员名册',
          displayText: '·  会员名册',
          children: {
            UnitVip: {
              name: '单位会员',
              type: ContentTypeMap.cards,
              dataGetter: () => {},
            },
            PersonalVip: {
              name: '个人会员',
              type: ContentTypeMap.cards,
              dataGetter: () => {},
            },
          },
        },
        council: {
          name: '理事会',
          displayText: '·  理事会',
          type: ContentTypeMap.contact,
          dataGetter: () => {
            return getCouncilOrSupervisor({
              dirCode: 'B0006',
            });
          },
        },
        supervise: {
          name: '监事会',
          displayText: '·  监事会',
          type: ContentTypeMap.contact,
          dataGetter: () => {
            return getCouncilOrSupervisor({
              dirCode: 'B0007',
            });
          },
        },
        secretariat: {
          name: '秘书处',
          displayText: '·  秘书处',
          type: ContentTypeMap.contact,
          dataGetter: () => {
            return getCouncilOrSupervisor({
              dirCode: 'B1003',
            });
          },
        },
        specialCommittee: {
          name: ' 专门委员会',
          displayText: '·  专门委员会',
          dataGetter: () => {},
          type: ContentTypeMap.tables,
          children: {
            dynamic: true,
            path: '/specialCommittee/:id',
            menuGetter: async () => {
              return await getProfessional({ dirCode: 'B0008' });
            },
          },
        },
        professionalCommittee: {
          name: '专业委员会',
          displayText: '·  专业委员会',
          type: ContentTypeMap.tables,
          dataGetter: () => {},
          children: {
            dynamic: true,
            path: '/professionalCommittee/:id',
            menuGetter: async () => {
              return await getProfessional({ dirCode: 'B0009' });
            },
          },
        },
      },
    },
    /**协会动态 */
    p_Dynamic: {
      name: '协会动态',
      menus: {
        associationNotice: {
          name: '协会通知',
          displayText: '· 协会通知',
          type: ContentTypeMap.textList,
          dataGetter: (params) => {
            return getArticleList({ ...params, dirCode: 'C0001' });
          },
        },
        importantNews: {
          name: '要闻资讯',
          displayText: '· 要闻资讯',
          type: ContentTypeMap.textList,
          dataGetter: (params) => {
            return getArticleList({ ...params, dirCode: 'C0002' });
          },
        },
      },
    },
    /**公告通知 */
    p_Notification: {
      name: '公告通知',
      menus: {
        recruitingInvestors: {
          name: '招募投资人公告',
          displayText: '· 招募投资人公告',
          type: ContentTypeMap.textList,
          dataGetter: (params) => {
            return getArticleList({ ...params, dirCode: 'D0001' });
          },
        },
        creditor: {
          name: '债权人会议公告',
          displayText: '· 债权人会议公告',
          type: ContentTypeMap.textList,
          dataGetter: (params) => {
            return getArticleList({ ...params, dirCode: 'D0002' });
          },
        },
        manager: {
          name: '管理人公告',
          displayText: '· 管理人公告',
          type: ContentTypeMap.textList,
          dataGetter: (params) => {
            return getArticleList({ ...params, dirCode: 'D0003' });
          },
        },
        bankruptcyCase: {
          name: '案件破产信息公告',
          displayText: '· 案破产件信息公告',
          type: ContentTypeMap.textList,
          dataGetter: (params) => {
            return getArticleList({ ...params, dirCode: 'D0004' });
          },
        },
        assetAuction: {
          name: '重大资产拍卖公告',
          displayText: '· 重大资产拍卖公告',
          type: ContentTypeMap.textList,
          dataGetter: (params) => {
            return getArticleList({ ...params, dirCode: 'D0005' });
          },
        },
        preReforming: {
          name: '预重整公告',
          displayText: '· 预重整公告',
          type: ContentTypeMap.textList,
          dataGetter: (params) => {
            return getArticleList({ ...params, dirCode: 'D0006' });
          },
        },
      },
    },
    /**理论探讨 */
    p_Theory: {
      name: '理论探讨',
      menus: {
        research: {
          name: '理论研究',
          displayText: '· 理论研究',
          type: ContentTypeMap.textList,
          dataGetter: (params) => {
            return getArticleList({ ...params, dirCode: 'E0001' });
          },
        },
        classicCase: {
          name: '典型案例',
          displayText: '· 典型案例',
          type: ContentTypeMap.textList,
          dataGetter: (params) => {
            return getArticleList({ ...params, dirCode: 'E0002' });
          },
        },
      },
    },
    /**培训学习 */
    p_Train: {
      name: '培训学习',
      menus: {
        notice: {
          name: '培训通知',
          displayText: '· 培训通知',
          type: ContentTypeMap.textList,
          dataGetter: (params) => {
            return getArticleList({ ...params, dirCode: 'F0001' });
          },
        },
        trends: {
          name: '培训动态',
          displayText: '· 培训动态',
          type: ContentTypeMap.mediaList,
          dataGetter: (params) => {
            return getArticleList({ ...params, dirCode: 'F0002' });
          },
        },
        course: {
          name: '培训课程',
          displayText: '· 培训课程',
          type: ContentTypeMap.mediaList,
          dataGetter: (params) => {
            return getArticleList({ ...params, dirCode: 'F0003' });
          },
        },
      },
    },
    /**法律法规 */
    p_lawsRegulations: {
      name: '法律法规',
      menus: {
        rule: {
          name: '法律法规、司法解释及司法文件',
          displayText: '· 法律法规、司法解释及司法文件',
          type: ContentTypeMap.textList,
          dataGetter: (params) => {
            return getArticleList({ ...params, dirCode: 'G0001' });
          },
        },
        regulations: {
          name: '部门规章、全国规范性文件',
          displayText: '· 部门规章、全国规范性文件',
          type: ContentTypeMap.textList,
          dataGetter: (params) => {
            return getArticleList({ ...params, dirCode: 'G0002' });
          },
        },
        court: {
          name: '地方法院指导文件',
          displayText: '· 地方法院指导文件',
          type: ContentTypeMap.textList,
          dataGetter: (params) => {
            return getArticleList({ ...params, dirCode: 'G0003' });
          },
        },
        government: {
          name: '地方政府指导文件',
          displayText: '· 地方政府指导文件',
          type: ContentTypeMap.textList,
          dataGetter: (params) => {
            return getArticleList({ ...params, dirCode: 'G0004' });
          },
        },
      },
    },
    /**文件下载 */
    p_download: {
      name: '文件下载',
      menus: {
        associationSystem: {
          name: '协会制度',
          displayText: '· 协会制度',
          type: ContentTypeMap.fileList,
          dataGetter: (params) => {
            return getAllFileList({ ...params, dirCode: 'H0001' });
          },
        },
        apply: {
          name: '入会申请',
          displayText: '· 入会申请',
          type: ContentTypeMap.fileList,
          dataGetter: (params) => {
            return getAllFileList({ ...params, dirCode: 'H0002' });
          },
        },
      },
    },
    // p_contactUs: {
    //   name: '联系我们',
    //   component: defineAsyncComponent(
    //     () => import('../components/pc/general-content/content/ContactUs.vue'),
    //   ),
    // },
  };
// var aa = require('../components/pc/general-content/content/ContactUs.vue');

/** */
export function GetGeneralContentConfig(
  configKey,
): GeneralContentMenuType | null {
  let result: GeneralContentMenuType | null = null;

  const configs = Object.entries(GeneralContentConfig);
  for (let i = 0; i < configs.length; i++) {
    const config = configs[i];
    if (config[0] === configKey) {
      // 如果一级路由就匹配上了， 直接返回配置
      result = config[1];
      break;
    } else {
      if (config[1].menus) {
        const menus = Object.entries(config[1].menus);
        // 如果一级路由没匹配上， 但是有menu，就查找menu
        for (let j = 0; j < menus.length; j++) {
          const item = menus[j];
          if (item[0] === configKey) {
            // 如果menu的key匹配上了， 返回menu的配置
            result = item[1];
            break;
          } else {
            // 如果menu的配置没匹配上，再判断menu的children能否匹配
            // 匹配上了就返回child的配置
            result =
              item[1].children && item[1].children[configKey]
                ? item[1].children[configKey]
                : null;
            if (result) break;
          }
        }
        if (result) break;
      } else {
        // 没有menu，查找下一个
        continue;
      }
    }
  }
  return result;
}

/** 第一级路由-如首页，协会概况等*/
const commonComponent = () =>
  import('@/components/pc/general-content/GeneralContent.vue');
/** 第二级路由-如协会简介，协会章程等 */
const commonDetailComponent = () =>
  import('@/components/pc/general-content/content/ContentRouter.vue');

/** textList 详情页面路由配置 */
const textDetailRouteName = 'Detail';
const textListRoutePath = textDetailRouteName + '/:id';
/** 文本列表点击详情页的组件 */
const textListDetailComponent = () =>
  import('@/components/pc/general-content/content/Text.vue');

/** 通用内容配置 转换成路由 */
export function GeneralContentConfig2Routes() {
  // 遍历所有一级配置，将其转换为一级路由
  // （和home同级）即nav点击时跳转的路由
  return Object.entries(GeneralContentConfig).map((item) => {
    let level2Route: any[] = [];
    // console.log('item[0]是什么？', item[0]);
    // console.log('item[1]是什么？', item[1]);

    if (item[1].menus) {
      // 解析每个一级配置中的menu配置， 并将它们放到二级路由中
      Object.entries(item[1].menus).forEach((content) => {
        if (content[1].children) {
          if (content[1].children.dynamic) {
            const dynamicConfig = content[1].children;
            console.log('dynamicConfig', dynamicConfig.path);

            level2Route.push({
              routePath:
                '/' + item[0] + '/' + item[1] + '/' + dynamicConfig.path,
              path: '/' + item[0] + '/' + dynamicConfig.path, // 即上面config中children里每个对象的key
              name: dynamicConfig.name,
              title: dynamicConfig.name,
              component: commonDetailComponent,
              meta: {
                type: 'pc',
              },
            });
          } else {
            // 如果有children， 需要把children中的所有内容也放到二级路由中
            Object.entries(content[1].children).forEach((child) => {
              level2Route.push({
                routePath: '/' + item[0] + '/' + child[0],
                path: '/' + item[0] + '/' + child[0], // 即上面config中children里每个对象的key
                name: child[0],
                title: child[1].name,
                component: commonDetailComponent,
                meta: {
                  type: 'pc',
                },
              });
            });
          }
        } else {
          const tempRoute = {
            path: '/' + item[0] + '/' + content[0], // 即上面config中menus里每个对象的key
            name: content[0],
            title: content[1].name,
            component: commonDetailComponent,
          };
          if (
            content[1].type === ContentTypeMap.textList ||
            content[1].type === ContentTypeMap.mediaList
          ) {
            const route =
              '/' + item[0] + '/' + content[0] + '/' + textListRoutePath;
            tempRoute['children'] = [
              {
                path: route, // 即上面config中menus里每个对象的key
                name: content[0] + textDetailRouteName,
                component: textListDetailComponent,
                meta: {
                  type: 'pc',
                },
              },
            ];
            item[1].routePath = route;
          }
          // 如果没有children， 直接将配置放到二级路由中
          level2Route.push(tempRoute);
        }
      });
    }

    item[1].routePath = '/' + item[0];
    // 一级路由
    return {
      path: '/' + item[0], // 即上面config中每个对象的key
      name: item[0],
      title: item[1].name,
      component: commonComponent,
      // 二级路由
      children: level2Route,
      meta: {
        type: 'pc',
      },
    };
  });
}

export function GeneralContentConfig2Nav() {
  let result: PytNavListType = [];
  Object.entries(GeneralContentConfig).forEach((config) => {
    result.push({
      name: config[1].name,
      path: `/${config[0]}${
        config[1].menus ? '/' + Object.keys(config[1].menus)[0] : ''
      }`,
    });
  });
  return result;
}
