/**
 * 后台接口
 */

import { http } from '..';
import Storage from '@/lib/utils/Storage';

const action = '/api/xh/file/oss/upload';
const headers = {
  token: Storage.get('token'),
};
/** 用户登录*/
function login(params) {
  return http.post('/user/login', { ...params });
}

/** 用户退出*/
function logout() {
  return http.post('/user/logout');
}

/** 添加角色*/
function addRole(params) {
  return http.post('/role/add', { ...params });
}

/** 修改角色*/
function updateRole(params) {
  return http.post('/role/update', { ...params });
}

/** 查询角色*/
function selectRolePage(params) {
  return http.post('/role/selectRolePage', { ...params });
}

/** 查询所有角色*/
function selectAll() {
  return http.post('/role/selectAll');
}

/** 删除角色*/
function deleteRole(params) {
  return http.post('/role/del', { ...params });
}

/**
 * 添加行业协会列表
 */
function addAssociation(params) {
  return http.post('/association/add', { ...params });
}

/**
 * 删除行业协会列表
 */
function delAssociation(params) {
  return http.post('/association/del', { ...params });
}

/**
 * 置顶行业协会列表
 */
function updateTopAssociation(params) {
  return http.post('/association/updateTop', { ...params });
}
/**
 * 编辑行业协会列表
 */
function updateAssociation(params) {
  return http.post('/association/update', { ...params });
}
/**
 * 分页获取合作logo信息 (司法网站地址)
 */
function getPartner(params) {
  return http.post('/partner/get/page', { ...params });
}
/**
 * 添加合作log信息 (司法网站地址)
 */
function addPartner(params) {
  return http.post('/partner/add', { ...params });
}
/**
 * 更新合作logo信息 (司法网站地址)
 */
function updatePartner(params) {
  return http.post('/partner/update', { ...params });
}
/**
 * 置顶按钮功能 (司法网站地址)
 */
function updateTopPartner(params) {
  return http.post('/partner/updateTop', { ...params });
}
/**
 * 删除合作logo信息(司法网站地址)
 */
function delPartner(params) {
  return http.post('/partner/del', { ...params });
}

//首页文件累计下载
function fileDownloadCount() {
  return http.post('/backup/get/fileDownloadCount');
}

//获取单位会员或者个人会员数量
function memberCount() {
  return http.post('/backup/get/memberCount');
}

// 获取 今日访问PV 今日访问 UV 访问累计PV 访问累计 UV
function PVAndUVData() {
  return http.post('/log/UVlist');
}

// 获取文章数量
function articleCountByDirCode(params) {
  return http.post('/backup/get/articleCountByDirCode', { ...params });
}

//协会概况 理事会列表获取获取
export function council(params) {
  return http.post('/position/get', { ...params });
}

//协会概况 分页获取理事会,监事会职位成员获取
export function councilPage(params) {
  return http.post('/position/page', { ...params });
}

//协会概况  理事会,监事会 置顶
export function updateTopCouncilPage(params) {
  return http.post('/position/updateTop', { ...params });
}

//协会概况  理事会,监事会 添加
export function addCouncilPage(params) {
  return http.post('/position/add', { ...params });
}

//协会概况  理事会,监事会 修改
export function updateCouncilPage(params) {
  return http.post('/position/update', { ...params });
}

//协会概况 理事会,监事会 删除
export function delCouncilPage(params) {
  return http.post('/position/del', { ...params });
}

// 后台-根据目录编号 获取委员会列表
function Scommittee(params) {
  return http.post('/committee/get/page', { ...params });
}

/**
 * 分页查询banner、ad
 */
function getAdList(params) {
  return http.post('/ad/page', { ...params });
}
/**
 * 添加广告、banner
 */
function addAdList(params) {
  return http.post('/ad/add', { ...params });
}
/**
 * 修改广告、banner
 */
function updateAdList(params) {
  return http.post('/ad/update', { ...params });
}
/**
 * 删除广告、banner
 */
function delAdList(params) {
  return http.post('/ad/del', { ...params });
}
/**
 * 分页查找所有的单位会员
 */
function getUnitMemberList(params) {
  return http.post('/unit/member/get/page', { ...params });
}
/**
 * 增加单位会员
 */
function addUnitMember(params) {
  return http.post('/unit/member/add', { ...params });
}
/**
 * 修改单位会员
 */
function updateUnitMember(params) {
  return http.post('/unit/member/update', { ...params });
}
/**
 * 置顶单位会员
 */
function updateTopUnitMember(params) {
  return http.post('/unit/member/updateTop', { ...params });
}
/**
 * 删除单位会员
 */
function delUnitMember(params) {
  return http.post('/unit/member/del', { ...params });
}
/**
 * 分页查找所有的个人会员
 */
function getPersonalMemberList(params) {
  return http.post('/personal/member/get/page', { ...params });
}
/**
 * 增加个人会员
 */
function addPersonalMember(params) {
  return http.post('/personal/member/add', { ...params });
}
/**
 * 修改个人会员
 */
function updatePersonalMember(params) {
  return http.post('/personal/member/update', { ...params });
}
/**
 * 置顶个人会员
 */
function updateTopPersonalUnitMember(params) {
  return http.post('/personal/member/updateTop', { ...params });
}
/**
 * 删除个人会员
 */
function delPersonalUnitMember(params) {
  return http.post('/personal/member/del', { ...params });
}

/**
 * 根据目录编号 获取 居委会列表
 */
function getCommitteeList(params) {
  return http.post('/committee/get/page', { ...params });
}

/**
 * 添加专门委员会、专业委员会
 */
function addCommittee(params) {
  return http.post('/committee/add', { ...params });
}
/**
 * 修改专门委员会、专业委员会
 */
function updateCommittee(params) {
  return http.post('/committee/update', { ...params });
}
/**
 * 删除专门委员会、专业委员会
 */
function delCommittee(params) {
  return http.post('/committee/del', { ...params });
}

/**
 * 专门委员会、专业委员会具体成员信息(根据CommitteeID和PositionId查询)
 */
function getCommitteeByCommitteeIdAndPid(params) {
  return http.post('/committee/getCommitteeByCommitteeIdAndPid', { ...params });
}
/**
 * 专门委员会-成员 专业委员会-成员 新增成员
 */
function addCommitteeMember(params) {
  return http.post('/committee/addCommitteeMember', { ...params });
}
/**
 * 专门委员会-成员 专业委员会-成员 更新成员(根据领导ID)
 */
function updateCommitteeMember(params) {
  return http.post('/committee/updateCommitteeMember', { ...params });
}
/**
 * 专门委员会-成员 专业委员会-成员 置顶成员
 */
function topCommitteeMember(params) {
  return http.post('/committee/topCommitteeMember', { ...params });
}
/**
 * 专门委员会-成员 专业委员会-成员 删除(通过领导ID删除)
 */
function deleteCommitteeMemberByLeaderId(params) {
  return http.post('/committee/deleteCommitteeMemberByLeaderId', { ...params });
}

/**
 * 分页查找所有文件信息
 */
function getFileList(params) {
  return http.post('/file/fileList', { ...params });
}
/**
 * 文件上传
 */
function uploadFileList(params) {
  return http.post('/file/oss/upload', { ...params });
}
/**
 * 添加文件信息
 */
function addFileList(params) {
  return http.post('/file/add', { ...params });
}
/**
 * 修改文件信息
 */
function updateFileList(params) {
  return http.post('/file/update', { ...params });
}
/**
 * 删除文件信息
 */
function deleteFileList(params) {
  return http.post('/file/delete', { ...params });
}

/**
 * 查询联系我们信息
 */
function getContactInfo(params) {
  return http.post('/contact/info', { ...params });
}
/**
 * 修改联系我们信息
 */
function updateContact(params) {
  return http.post('/contact/update', { ...params });
}

/**
 * 分页获取文章列表
 */
function getArticle(params) {
  return http.post('/article/get/page/byDirCode', { ...params, flag: '2' });
}
/**
 * 置顶按钮功能
 */
function updateTopArticle(params) {
  return http.post('/article/updateTop', { ...params });
}
/**
 * 根据article_id删除文章
 */
function delArticle(params) {
  return http.post('/article/del', { ...params });
}

/**
 * 新增文章
 */
function addArticle(params) {
  return http.post('/article/add', { ...params });
}
/**
 * 修改文章
 */
function updateArticle(params) {
  return http.post('/article/update', { ...params });
}
/**
 * 搜索文章
 */
function searchArticle(params) {
  return http.post('/article/search/page', { ...params });
}

//修改密码
function updatePwd(params) {
  return http.post('/user/updatePwd', { ...params });
}

export default {
  action,
  headers,
  logout,
  login,
  addRole,
  updateRole,
  selectAll,
  selectRolePage,
  deleteRole,
  addAssociation,
  delAssociation,
  updateTopAssociation,
  updateAssociation,
  getPartner,
  addPartner,
  updatePartner,
  updateTopPartner,
  delPartner,
  fileDownloadCount,
  memberCount,
  PVAndUVData,
  articleCountByDirCode,
  council,
  councilPage,
  updateTopCouncilPage,
  addCouncilPage,
  updateCouncilPage,
  delCouncilPage,
  Scommittee,
  getAdList,
  addAdList,
  updateAdList,
  delAdList,
  getUnitMemberList,
  addUnitMember,
  updateUnitMember,
  updateTopUnitMember,
  delUnitMember,
  getPersonalMemberList,
  addPersonalMember,
  updatePersonalMember,
  updateTopPersonalUnitMember,
  delPersonalUnitMember,
  getCommitteeList,
  addCommittee,
  updateCommittee,
  delCommittee,
  getCommitteeByCommitteeIdAndPid,
  addCommitteeMember,
  updateCommitteeMember,
  topCommitteeMember,
  deleteCommitteeMemberByLeaderId,
  getFileList,
  uploadFileList,
  addFileList,
  updateFileList,
  deleteFileList,
  getContactInfo,
  updateContact,
  getArticle,
  updateTopArticle,
  delArticle,
  addArticle,
  updateArticle,
  searchArticle,
  updatePwd,
};
