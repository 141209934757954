/**
 * 封装提醒
 * 默认从屏幕右上角弹出
 */
import { ElNotification } from 'element-plus';

export class Notification {
  static success(message: string) {
    return ElNotification.success({
      type: 'success',
      title: '正确!',
      message: message,
      iconClass: 'el-icon-check',
    });
  }
  static error(message: string, configs = {}) {
    return ElNotification.error({
      type: 'error',
      title: '错误!',
      message: message,
      iconClass: 'el-icon-close',
      ...configs,
    });
  }
  static warning(message: string) {
    return ElNotification.warning({
      type: 'warning',
      title: '警告!',
      message: message,
      iconClass: 'el-icon-warning-outline',
    });
  }
  static info(message: string) {
    return ElNotification.info({
      type: 'info',
      title: '消息',
      message: message,
      iconClass: 'el-icon-info',
    });
  }
}

export const Notify = Notification;

export default Notification;
