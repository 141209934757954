import Notification from '@/lib/notification';
import axios, {
  AxiosInstance,
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios';
// import { debounced } from 'lodash';
import { warn } from 'console';
import { config } from 'process';
// import qs from 'qs';
import { CommonResponseType } from '../types/api';
import Storage from '@/lib/utils/Storage';
import { ElMessage } from 'element-plus';
import Router from '../router';

axios.defaults.timeout = 10000;
axios.defaults.baseURL = '/api/xh/';
// axios.defaults.baseURL = 'http://192.168.255.21:9090';
// axios.defaults.baseURL = 'http://192.168.255.43:9090';

const getToken = () => {
  return Storage.get('token');
};

export class HttpRequest {
  public static axiosIns: AxiosInstance; // axios实例
  private axiosRequestConfig: AxiosRequestConfig = {
    timeout: 1000 * 12,
  }; // axios 配置

  constructor() {
    this.axiosRequestConfig.timeout = 10000; // 全局超时时限
    const axiosIns = axios.create(this.axiosRequestConfig);
    axiosIns.interceptors.request.use(this.onRequest.bind(this));
    axiosIns.interceptors.response.use(
      this.onResponse.bind(this),
      this.onResponseError,
    );
    HttpRequest.axiosIns = axiosIns;
  }

  public get<T = any, R = AxiosResponse<T>>(
    url: string,
    config?: AxiosRequestConfig,
  ): Promise<R> {
    return HttpRequest.axiosIns.get(url, config);
  }

  public post<T = any, R = CommonResponseType<T> & AxiosResponse<T>>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig,
  ): Promise<R> {
    return HttpRequest.axiosIns.post(url, data, config);
  }

  public put<T = any, R = AxiosResponse<T>>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig,
  ): Promise<R> {
    return HttpRequest.axiosIns.put(url, data, config);
  }

  public patch<T = any, R = AxiosResponse<T>>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig,
  ): Promise<R> {
    return HttpRequest.axiosIns.patch(url, data, config);
  }

  public delete<T = any, R = AxiosResponse<T>>(
    url: string,
    config?: AxiosRequestConfig,
  ): Promise<R> {
    return HttpRequest.axiosIns.delete(url, config);
  }

  public head<T = any, R = AxiosResponse<T>>(
    url: string,
    config?: AxiosRequestConfig,
  ): Promise<R> {
    return HttpRequest.axiosIns.head(url, config);
  }

  /**
   * axios 请求拦截封装
   * 请求拦截思路：
   * 1. 根据后台返回到expireTime，预判到期时间
   * 2. 过期则刷新refreshToken，没过期则继续请求
   * 3. 过期时间之后的批量请求，统一在refreshToken之后继续请求
   * @param request
   */
  private onRequest(
    request: AxiosRequestConfig,
  ): AxiosRequestConfig | Promise<AxiosRequestConfig> {
    const token = getToken();
    if (token) {
      (request as any).headers['token'] = token;
    }
    return request;
  }

  // /** 错误消息做个3000ms的防抖 */
  // private responseErrorDebouncde = debounced((msg) => {
  //   Notification.error(msg);
  // }, 3000);

  /**
   * axios 响应拦截封装
   * 响应拦截思路：
   * 1. response.data.code === 0代表后台响应数据正常，其他code码见代码
   * 2. 批量请求多个接口，多个接口同时过期返回4004，拦截所有4004请求
   * 3. 拦截之后的所有4004请求，统一在refreshToken之后继续请求
   * @param response
   */
  private onResponse(response: AxiosResponse): Promise<AxiosResponse> | any {
    if (response.data.code) {
      switch (response.data.code) {
        case '0':
          break;
        case '000013':
          Router.replace('/login');
          Storage.clear();
          break;
        default:
          // this.responseErrorDebouncde(response.data.msg);
          break;
      }
    }

    return { ...response.data, response };
  }

  /**
   * axios 错误拦截
   * @param error
   */
  private onResponseError(error: AxiosError) {
    // TODO: 处理请求错误
    if (error.message.indexOf('Network Error') >= 0) {
      // 网络错误
      Notification.error('网络错误，请检查网络状态');
    } else if (error.message.indexOf('timeout') >= 0) {
      // 网络请求超时
      Notification.error('网络请求超时');
    } else if (error.response && error.response.status) {
      const status = error.response.status;
      switch (status.toString()[0]) {
        case '4':
          // 4开头的http status，如不存在的接口，接口被删除等
          Notification.error('请求接口失败，请检查接口参数');
          break;
        case '5':
          // 5开头的http status
          Notification.error('抱歉，服务器开了个小差，请稍后重试');
          break;
        default:
          break;
      }
    }
  }

  /**
   * 重定向到登录界面
   */
  redirectToLogin() {
    // 清除缓存信息
    localStorage.removeItem('pyt-m');
    localStorage.removeItem('expireTime');
  }
}

export default HttpRequest;

export const http = new HttpRequest();
